<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../../assets/contract.png" alt />
        <span class="center">合同管理</span>
        <img src="../../../assets/right.png" alt />
        <span class="center">合同模板</span>
      </div>
      <div style="display: flex; align-items: center">
        <div style="text-align:left;">
          <span class="seach_text">关键字：</span>

          <el-input
            placeholder="请输入合同模板名称、合同描述"
            v-model="formInline.keywords"
            @input="loadData('seath')"
            size="mini"
            style="margin-right: 10px; width: 300px"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </div>
        <el-button
          v-access
          data-ctrl="contractTemplateAdd"
          v-prev
          path="/system/contract/contractTemplate"
          size="small"
          icon="el-icon-plus"
          type="primary"
        >新建</el-button>
      </div>
    </div>

    <el-table :data="list" style="width: 100%">
      <el-table-column label="合同模板名称">
        <template slot-scope="scope">
          <span
            @click="goDetail({ code: scope.row.code })"
            class="font-color-brand"
          >{{ scope.row.name }}</span>
        </template>
      </el-table-column>

      <el-table-column label="合同模板描述">
        <template slot-scope="scope">{{ scope.row.desc }}</template>
      </el-table-column>

      <!-- <el-table-column label="内容" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.content }}
        </template>
      </el-table-column>-->

      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="shuxian" v-access data-ctrl="contractTemplateDel">
            <!-- <el-popconfirm
                @confirm="del({ code: scope.row.code })"
                confirmButtonText="确定"
                cancelButtonText="取消"
                icon="el-icon-info"
                iconColor="red"
                title="确定删除此这条数据？"
              >
                <el-button
                  slot="reference"
                  style="margin-left: 10px"
                  type="text"
                  >删除</el-button
                >
            </el-popconfirm>-->

            <el-popover
              placement="top"
              width="400"
              trigger="manual"
              :ref="'popover-' + scope.row.id"
            >
              <div class="del-pop">
                <img src="../../../assets/del.png" alt />
              </div>
              <div class="del-title">删除模板</div>
              <div class="del-sumtitle">是否要删除该模板？</div>
              <div class="del-btn">
                <el-button type="primary" @click="del(scope.row)" plain>确认</el-button>
                <el-button type="info" @click="doCancel(scope.row)" plain>取消</el-button>
              </div>
              <el-button
                slot="reference"
                @click="pOpen(scope.row)"
                style="margin-left: 10px"
                type="text"
              >删除</el-button>
            </el-popover>

            <el-divider direction="vertical"></el-divider>
          </div>
          <div class="shuxian" v-access data-ctrl="contractTemplateEdit">
            <span class="clickColor" @click="goEdit(scope.row.code)">编辑</span>
            <el-divider direction="vertical"></el-divider>
          </div>

          <span
            class="clickColor"
            @click="goSealEdit(scope.row.code)"
            v-access
            data-ctrl="contractTemplate.setField"
          >指定签署位置</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
    <addDialog :dialogVisible.sync="addDialogVisible" @close="close()"></addDialog>
    <editDialog :dialogVisible.sync="editDialogVisible" :code="focusCode" @close="close()"></editDialog>
  </div>
</template>

<script>
//接口：
import { api } from '/src/api/base';
//新增接口
export const ContractTemplateAdd = api('/contractTemplate')(
  'contractTemplate.add.json'
);
//查询列表接口
export const ContractTemplateList = api('/contractTemplate')(
  'contractTemplate.list.json'
);
//查询详情接口
export const ContractTemplateDetail = api('/contractTemplate')(
  'contractTemplate.describe.json'
);
//编辑接口
export const ContractTemplateEdit = api('/contractTemplate')(
  'contractTemplate.edit.json'
);
//删除接口
export const ContractTemplateDel = api('/contractTemplate')(
  'contractTemplate.del.json'
);
export default {
  components: {
    addDialog: () => import('./Add'),
    editDialog: () => import('./Edit')
  },
  data () {
    return {
      focusCode: '',

      pageSize: 10,
      pageNumber: 1,
      total: 0,
      formInline: {
        keywords: ''
      },
      list: [],
      addDialogVisible: false,
      editDialogVisible: false,
      dialogData: null
    };
  },
  created () {
    this.loadData();
  },
  methods: {
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData (seath) {
      if (seath) {
        this.pageNumber = 1;
      }
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline,
        $ignoreRepeat: true
      };
      ContractTemplateList(params).then(res => {
        this.list = res.list;
        this.total = res.total;
      });
    },
    pOpen (row) {
      this.$refs['popover-' + row.id].doShow();
      console.log(this.$refs);
    },
    doCancel (row) {
      this.pClose(row.id);
    },

    pClose (id) {
      this.$refs['popover-' + id].doClose();
    },
    onSubmit () {
      console.log('submit!');
    },
    addObject () {
      this.addDialogVisible = true;
      console.log(this.formInline);
    },
    goEdit (obj) {
      this.$router.push({
        path: '/system/contract/contractTemplate',
        query: {
          code: obj
        }
      });
    },
    goSealEdit (code) {
      this.$router.push({
        path: '/system/contract/pdf',
        query: {
          code: code,
          type: 'template'
        }
      });
    },
    goDetail (obj) {
      this.$router.push({
        path: '/system/contractTemplate/detail',
        query: obj
      });
    },
    del (obj) {
      console.log(obj.id);
      ContractTemplateDel({ code: obj.code }).then(res => {
        console.log(res);
        this.$message.success('内容成功删除！');
        this.doCancel(obj);
        this.loadData();
      });
    },
    close () {
      this.loadData();
    },
    putAway () {
      this.formInline.keywords = '';
      this.pageNumber = 1;
      this.loadData();
    }
  }
};
</script>

<style lang="scss" scoped>
// .header {
//   display: flex;
//   justify-content: space-between;
//   border: none;
//   height: 40px;
//   align-items: center;
//   font-weight: 400;
//   color: #606266;
//   cursor: pointer;
//   .header_top {
//     display: flex;
//     align-items: center;
//     img:nth-child(1) {
//       width: 20px;
//       height: 17px;
//     }
//     img:nth-child(2) {
//       width: 9px;
//       height: 10px;
//     }
//     span {
//       margin: 0 10px;
//       font-size: 16px;
//       color: #606060;
//     }
//   }
// }
.clickColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
